import axios from 'axios';
import { removeAuthInfoFromLocalStorage } from '../utils/helper';
import { getCompanyId } from '../config';

// const local = 'https://tbn-customer-prod.uc.r.appspot.com/api';
// const local = 'https://tbn-customer-qa.uc.r.appspot.com/api';
const local = 'https://tbn-customer-stage.uc.r.appspot.com/api';
// const local = 'https://tbn-customer-dev.uc.r.appspot.com/api';
// const local = 'http://localhost:8080/api';
export const baseURL = local;
const baseService = axios.create({ baseURL });
export const Service = axios.create({ baseURL });
const quickBaseUrl = 'https://thebusnetwork.quickbase.com/db';
export const quickBaseService = axios.create({ baseURL: quickBaseUrl });

baseService.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : '';
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
            config.params = { companyId: getCompanyId(), ...config.params };
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

baseService.interceptors.response.use(
    (config) => {
        let publicRouteRegex = /\/p\//gim;
        let currentVersion = localStorage.getItem('customerportal_version');
        if (currentVersion === config.headers.customerportal_version) {
            return config;
        }
        // not to reset user auth if it's coming from routes containing /p/
        else if (publicRouteRegex.exec(window.location.href)) {
            return config;
        } else {
            removeAuthInfoFromLocalStorage();
            window.location.href = '/auth/login';
        }
    },
    (error) => {
        console.log('err', error);
        if (error.response?.data?.message) {
            return Promise.reject({
                response: { data: { error: error.response.data.message || 'please login first' } },
            });
        }
        return Promise.reject(error);
    }
);

export default baseService;
